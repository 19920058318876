import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
}) => {
	const handleChange = (e) => {
		const value = e.target.value;

		if (value.includes("toggle-all")) {
			// Verifica se todos estão selecionados; se sim, desmarca tudo, caso contrário, seleciona todos
			if (selectedQueueIds.length === userQueues.length) {
				onChange([]);
			} else {
				onChange(userQueues.map((userQueue) => userQueue.id));
			}
		} else {
			onChange(value);
		}
	};

	return (
		<div>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
				>
					<MenuItem dense value="toggle-all">
						<Checkbox
							color="primary"
							checked={selectedQueueIds.length === userQueues.length}
							indeterminate={
								selectedQueueIds.length > 0 && selectedQueueIds.length < userQueues.length
							}
						/>
						<ListItemText
							primary={"Selecionar Tudo"}
						/>
					</MenuItem>
					{userQueues?.length > 0 &&
						userQueues.map(queue => (
							<MenuItem dense key={queue.id} value={queue.id}>
								<Checkbox
									style={{
										color: queue.color,
									}}
									size="small"
									color="primary"
									checked={selectedQueueIds.indexOf(queue.id) > -1}
								/>
								<ListItemText primary={queue.name} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsQueueSelect;
