import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonWithSpinner from "../ButtonWithSpinner";

const MessageEditModal = ({ modalOpen, onClose, mensagem }) => {

	const [bodyMessage, setBodyMessage] = useState("");

	useEffect(() => {
		if (mensagem?.body) {
			setBodyMessage(mensagem.body);
		}
	}, [mensagem]);


	const handleClose = () => {
		onClose(bodyMessage);
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle id="form-dialog-title">
				Editar mensagem
			</DialogTitle>
			<DialogContent dividers>
				<TextField
					label="Mensagem"
					variant="outlined"
					multiline
					fullWidth
					value={bodyMessage}
					onChange={(e) => setBodyMessage(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					color="secondary"
					variant="outlined"
				>
					Cancelar
				</Button>
				<ButtonWithSpinner
					variant="contained"
					type="button"
					onClick={() => handleClose()}
					color="primary"
				>
					Editar
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default MessageEditModal;